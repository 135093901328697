import logo from './logo.svg';
import './App.css';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const Preload = React.lazy(() => import('./components/preload'));
const Layout = React.lazy(() => import('./layouts'));

function App() {
  return (
    <Suspense fallback={<Preload />}>
      <Routes>
        <Route path="*" element={<Layout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
