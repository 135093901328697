import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import GoogleFontLoader from 'react-google-font-loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Figtree',
            weights: [300, 400, 500, 600, 700, 800, 900],
          },
          {
            font: 'Mohave',
            weights: [300, 400, 500, 600, 700],
          },
        ]}
      />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
